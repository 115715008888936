import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'


$(document).ready( init)
let isMobile = $(window).width() < 700;

function init() {

  if($('.animated-content').length) {


    gsap.registerPlugin(ScrollTrigger)

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".animated-content",
        endTrigger: ".animated-image-4-1",
        start: "top top",
        end: "+=8000",
        //markers: true,
        scrub: 3,
        pin: true,
        snap: "labels"
      }
    });

    initAnimation(tl)

  }

}


function initAnimation(tl) {

  const ease = 'expoScale(0.5,7,none)';
  
  tl.add("image1");
  tl.from(".animated-image-1-1", {autoAlpha: 1, duration: 10, ease: ease });

  tl.add("image2");
  tl.from(".animated-image-1-2", {autoAlpha: 1, duration: 10, ease: ease }, "<3");

  tl.add("image3");
  tl.from(".animated-image-1-3", {autoAlpha: 1, duration: 10, ease: ease }, "<3");

  tl.add("image4");
  tl.fromTo(".animated-image-1-4", {autoAlpha: 0,}, {autoAlpha: 1, duration: 10, ease: ease }, "<3");
  
    tl.add("text1");
      tl.to("#animated-text-1", isMobile ? {x: 600, duration: 5, ease: ease } : {y: -600, duration: 5, ease: ease });
    tl.add("text2");
      tl.fromTo("#animated-text-2", isMobile ? {autoAlpha: 0, x: -600} : {autoAlpha: 0, y: 600}, isMobile ? {autoAlpha: 1, x: 0, duration: 5, ease: ease } : {autoAlpha: 1, y: 0, duration: 5, ease: ease }, "<");

  tl.add("image2-1");
  tl.from(".animated-image-2-1", isMobile ? {x: 600, duration: 5, ease: ease } : {y: 600, duration: 5, ease: ease } , "<");

  tl.add("image2-2");
  tl.fromTo(".animated-image-2-2", {autoAlpha: 0,}, {autoAlpha: 1, duration: 5, ease: ease } , "<5");
  
    tl.add("text2");
      tl.to("#animated-text-2", isMobile ? {x: 600, duration: 5, ease: ease } : {y: -600, duration: 5, ease: ease });
    tl.add("text3");
      tl.fromTo("#animated-text-3", isMobile ? {autoAlpha: 0, x: -600} : {autoAlpha: 0, y: 600}, isMobile ? {autoAlpha: 1, x: 0, duration: 5, ease: ease} : {autoAlpha: 1, y: 0, duration: 5, ease: ease });

  tl.add("image3-1")
  tl.from(".animated-image-3-1", isMobile ? {x: 600, duration: 5, ease: ease } : {y: 600, duration: 5, ease: ease }, "<");

  tl.add("image3-2")
  tl.fromTo(".animated-image-3-2", {autoAlpha: 0}, {autoAlpha: 1, duration: 5, ease: ease }, "<5");
  
    tl.add("text3");
      tl.to("#animated-text-3", isMobile ? {x: 600, duration: 5, ease: ease} : {y: -600, duration: 5, ease: ease});

  
  tl.add("image4")
  tl.fromTo(".animated-image-4-1", {autoAlpha: 0,}, {autoAlpha: 1, duration: 5, ease: ease }, "<5");

    tl.add("text4");
    tl.fromTo("#animated-text-4", isMobile ? {autoAlpha: 0, x: -600} : {autoAlpha: 0, y: 600}, isMobile ? {autoAlpha: 1, x: 0, duration: 5, ease: ease } : {autoAlpha: 1, y: 0, duration: 5, ease: ease }, "<");

}