import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

$(document).ready(function(){
  if( ! getCookie( 'mg-privacy-cookies' ) ){
    const $notice = $('.js-cookie-notice')
    $notice.slideDown()
    $('.js-cookie-notice .js-close').click( function(){
      $notice.slideUp()
      setCookie('mg-privacy-cookies', 'true', 1);
    })
  }
})
